import { Icon } from '@/components/general/Icon';
import { usePreferredLocation } from '@/nanostores/PreferredLocation/hooks';
import type { Location, SiteType } from '@wavebi/tsunami-cms-admin';
import { type FC, useMemo } from 'react';

export type SocialsProps = {
  socials?: {
    site: SiteType<'homeDecor1'>['socialMedia'][number]['site'];
    url: string;
  }[];
  isPhoneLinkVisible?: boolean;
  locations?: Location[];
};

export const Socials: FC<SocialsProps> = ({
  socials,
  isPhoneLinkVisible = false,
  locations = [],
}) => {
  const [preferredLocation] = usePreferredLocation();
  const selectedLocation = useMemo(
    () => locations[preferredLocation] ?? locations[0],
    [locations, preferredLocation]
  );
  const { email, phone } = selectedLocation ?? {};

  return (
    <div className="footer-socials flex flex-col items-center gap-32">
      <div className="flex flex-col items-center gap-10">
        {!!email && (
          <div className="flex items-center gap-12">
            <Icon
              icon="email"
              className="footer-social-icon flex-[0_0_30px] fill-text-primary"
              width={30}
              height={30}
            />
            <a
              className="break-all text-center text-18"
              href={`mailto:${email}`}
            >
              {email}
            </a>
          </div>
        )}

        {isPhoneLinkVisible && !!phone && (
          <div className="flex items-center gap-12">
            <Icon
              icon="phone"
              className="footer-social-icon flex-[0_0_30px] fill-text-primary"
              width={30}
              height={30}
            />
            <a className="text-18" href={`tel:${phone}`}>
              {phone}
            </a>
          </div>
        )}
      </div>

      {!!socials?.length && (
        <ul className="m-0 flex list-none gap-12 p-0">
          {socials.map(({ site, url }, index) => {
            return (
              <li key={index} className="flex items-center justify-center">
                <a href={url} target="_blank">
                  <Icon
                    icon={site}
                    className="footer-social-icon fill-text-primary"
                    height={40}
                  />
                  <span className="sr-only">{site}</span>
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
